import { useProjectPortfolio } from "../portfolio/project";
import { UserAuth } from "../context/AuthContext";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useState } from "react";
import { Link } from "react-router-dom";
import Toast from "../components/Toast";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL; 
const SUPABASE_STORAGE_URL = process.env.REACT_APP_SUPABASE_STORAGE_URL;

const ProjectCover = ({ project }) => {
  const { deletProject } = useProjectPortfolio();
  const { session } = UserAuth();
  const navigate = useNavigate();

// Redirection to the project sections : 
const handleNavigation = () => {
  navigate(`/sections/${project._id}`);
};

 

// edit 
const [isModalOpen, setIsModalOpen] = useState(false);
const [editProject, setEditProject] = useState({ ...project });
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const { updateProject } = useProjectPortfolio();
const [toast, setToast] = useState({ show: false, message: "", type: "" });  // Toast state


const handleDeleteProject = async (pid, e) => {
  e.stopPropagation(); // Prevent card click propagation
  
  const confirmDelete = window.confirm("Are you sure you want to delete this project?");
  if (!confirmDelete)  return;

  try {
      const { success, message } = await deletProject(pid ,e);
      console.log("Response from deletProject:", { success, message });

      setToast({
          show: true,
          message: message || (success ? "Project deleted successfully!" : "Failed to delete project"),
          type: success ? "success" : "error",
      });
      

      // Hide toast after 3 seconds
      setTimeout(() => {
          setToast({ show: false, message: "", type: "" });
      }, 3000);

  } catch (error) {
      console.error("Error in handleDeleteProject:", error);

      setToast({
          show: true,
          message: "An unexpected error occurred while deleting the project.",
          type: "error",
      });

      setTimeout(() => {
          setToast({ show: false, message: "", type: "" });
      }, 3000);
  }
};


 // Update project
 const handleEditProject = async () => {
  if (!editProject.title || !editProject.role || !editProject.category) {
      setToast({
          show: true,
          message: "Please fill in all fields!",
          type: "error",
      });
      setTimeout(() => setToast({ show: false, message: "", type: "" }), 3000);
      return;
  }

  const { success, message } = await updateProject(editProject);

  // Show toast notification
  setToast({
      show: true,
      message: message || (success ? "Project updated successfully!" : "Failed to update project"),
      type: success ? "success" : "error",
  });

  // Close modal on success
  if (success) setIsEditModalOpen(false);
  //navigate(`/`);

  // Reset toast after a delay
  setTimeout(() => {
      setToast({ show: false, message: "", type: "" });
  }, 3000);
};




  return (
    <>
      {/* Toast */}
      <Toast show={toast.show} message={toast.message} type={toast.type} />
      {/* Card Template */}
      <div 
      onClick={handleNavigation}
      className="cursor-pointer group relative rounded-lg shadow-lg overflow-hidden h-80 transition-transform duration-300 hover:scale-105">
        {/* Buttons */}
        {session?.user ? (
          <div className="absolute top-2 left-2 z-10 flex space-x-2">
          <button
              className="bg-blue-500 text-white text-xs px-3 py-1 rounded hover:bg-blue-600 transition flex items-center space-x-1"
              onClick={(e) => {
                e.stopPropagation(); // Prevent navigation
                  console.log("Editing project:", project); // Log project data
                  setEditProject(project); // Populate form with project data
                  setIsEditModalOpen(true); // Open modal
              }}
          >
              <FaEdit />
              <span>Edit</span>
          </button>
          <button
              className="bg-red-500 text-white text-xs px-3 py-1 rounded hover:bg-red-600 transition flex items-center space-x-1"
              onClick={(e) => handleDeleteProject(project._id, e)} // Pass event to the handler

          >          
              <RiDeleteBin6Line />
              <span>Delete</span>
            </button>
          </div>
          
        ) : null}
      {/* Wrap the card with Link */}

        {/* Cover Image */}
        <div class="cursor-pointer" >
        <img 
          src={`${project.cover}`}
          alt={`${project.title} animation project by Lotfi Jilal`} 
          className="w-full h-80 object-cover transform transition-transform duration-300 group-hover:scale-110"
        />
        </div>
 
        {/* Gradient Layer */}
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-0 group-hover:opacity-50 transition-opacity duration-300 "></div>

        {/* Text Layer */}
        <div className="absolute inset-0 flex flex-col justify-end p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <h2 className="text-lg font-semibold text-white">{project.title}</h2>
          <p className="text-sm text-gray-300">{project.category}</p>
        </div>
      </div>
      
{/* Modal */}
       {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-lg p-6 relative ">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
              onClick={() => setIsEditModalOpen(false)}
            >
              ✕
            </button>

            <h2 className="text-2xl font-semibold mb-4">Edit Project</h2>

            <div className="space-y-4">
{/* Cover */}
<div>
    <label
        htmlFor="cover"
        className="block text-sm font-medium text-gray-700"
    >
        Cover Image
    </label>
    <input
        id="cover"
        name="cover"
        type="file"
        accept="image/*"
        onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
                setEditProject((prev) => ({ ...prev, cover: file })); // Update the state
            }
        }}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
        required
    />
    {editProject.cover && (
        <p className="mt-1 text-sm text-gray-600">
            {editProject.cover.name || "Current cover is set"}
        </p>
    )}
</div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  value={editProject?.title || ""}
                  onChange={(e) =>
                      setEditProject((prev) => ({ ...prev, title: e.target.value }))
                  }
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Role</label>
                <input
                  type="text"
                  value={editProject?.role || ""}
                  onChange={(e) =>
                      setEditProject((prev) => ({ ...prev, role: e.target.value }))
                  }
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Category</label>
                <select
                  value={editProject?.category || ""}
                  onChange={(e) =>
                      setEditProject((prev) => ({ ...prev, category: e.target.value }))
                  }
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select a category</option>
                  <option value="Photography">Photography</option>
                  <option value="Illustration">Illustration</option>
                  <option value="Motion Design">Motion Design</option>
                  <option value="Frame by Frame">Frame-by-Frame</option>
                  <option value="Assets & Tools">Assets & Tools</option>
                </select>
              </div>
              <button
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                onClick={handleEditProject}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectCover;
