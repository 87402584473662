import { useProjectPortfolio } from "../portfolio/project";
import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient"; // Import supabase client
import { useNavigate } from "react-router-dom"; // For navigation
import Toast from "../components/Toast";
const API_URL = process.env.REACT_APP_API_URL; 


const AddProjectPage = () => {
  const [newProject, setNewProject] = useState({
    title: "",
    cover: "",
    role: "",
    category: "",
    userId: "", // Will be set from Supabase auth
  });

  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const [coverFile, setCoverFile] = useState(null);
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data: session, error } = await supabase.auth.getSession();

        if (error) {
          console.error("Error fetching session:", error);
          return;
        }

        const user = session?.session?.user;
        if (user) {
          setNewProject((prev) => ({
            ...prev,
            userId: user.id,
          }));
        }
      } catch (err) {
        console.error("Error fetching user:", err);
      }
    };

    fetchUser();
  }, []);

  // add project with section redirection

  const handleAddProject = async () => {
    const formData = new FormData();
    formData.append("title", newProject.title);
    formData.append("role", newProject.role);
    formData.append("category", newProject.category);
    formData.append("userId", newProject.userId);
    formData.append("cover", coverFile);
    //console.log("API_URL:", API_URL);
    //console.log("New Project Data:", newProject);
    //console.log("Cover File:", coverFile);

    try {
      const res = await fetch(`${API_URL}/api/projects`, {
        method: "POST",
        body: formData,
      });

      const data = await res.json();


      if (!data.success) {
        setToast({ show: true, message: data.message || "Failed to add project", type: "error" });
      } else {
        setToast({ show: true, message: "Project added successfully!", type: "success" });

        // Redirect to AddSectionPage with the projectId
        navigate(`/addsection/${data.data._id}`); // Replace `data.data._id` with your API's project ID key
      }
    } catch (error) {
      console.error("Error adding project:", error);
    } finally {
      setTimeout(() => {
        setToast({ show: false, message: "", type: "" });
      }, 3000);
    }
  };

  return (
<div>
<body class="bg-white flex justify-center items-center min-h-screen">
  <div class="bg-white border-2 rounded-lg p-6 max-w-md w-full">
    <h1 class="text-2xl font-semibold text-gray-800 mb-4">Add New Project</h1>
      {/* Toast */}
      <Toast show={toast.show} message={toast.message} type={toast.type} />
    <div  class="space-y-4">
      {/* UserId (this will be auto-filled with the current user's id) */}
      <div>
        <label htmlFor="userId" className="block text-sm font-medium text-gray-700">UserId</label>
        <input
            type="text"
            id="userId"
            name="userId"
            value={newProject.userId}
            disabled
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
          />
      </div>
      {/* Title */}
      <div>
        <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
        <input type="text" id="title"  placeholder="Enter project title"
           name="title"
           value ={newProject.title}
           onChange ={(e) => setNewProject ({ ...newProject, title: e.target.value})}
           class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800" required/>
      </div>
      {/* Cover*/}
      <div>
        <label for="cover" class="block text-sm font-medium text-gray-700">Cover Image</label>
        <input          
          id="cover"   
          name="cover"
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            console.log("Selected file:", file); // Logs the selected file
            setCoverFile(file);
          }}
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800" required/>
      </div>
      {/* Role */}
      <div>
        <label for="role" class="block text-sm font-medium text-gray-700">Role</label>
        <input type="text" id="role"  placeholder="Enter your role"
          name="role"
          value ={newProject.role}
          onChange ={(e) => setNewProject ({ ...newProject, role: e.target.value})}
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800" required/>
      </div>
      {/* Category */}
      <div>
        <label for="category" class="block text-sm font-medium text-gray-700">Category</label>
        <select id="category" 
          name="category"
          value ={newProject.category}
          onChange ={(e) => setNewProject ({ ...newProject, category: e.target.value})}
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800" required>
          <option value="" disabled selected>Select a category</option>
          <option value="Photography">Photography</option>
          <option value="Illustration">Illustration</option>
          <option value="Motion Design">Motion Design</option>
          <option value="Frame by Frame">Frame-by-Frame</option>
          <option value="Assets & Tools">Assets & Tools</option>
        </select>
      </div>
      {/* Submit Button */}
      <div>
        <button 
          type="button"
          onClick={handleAddProject}
          class="w-full bg-blue-500 text-white font-medium py-2 px-4 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          Add Project
        </button>
      </div>
    </div>
  </div>
</body>
</div>
  );
};

export default AddProjectPage;
