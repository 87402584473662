
import { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import ProjectCover from "../components/ProjectCover";
import { UserAuth } from "../context/AuthContext";
import { useProjectPortfolio } from "../portfolio/project";
//import { motion } from "framer-motion";
import { motion, AnimatePresence } from "framer-motion";
import Toast from "../components/Toast";
import { Helmet } from 'react-helmet'; // Install with npm install react-helmet
import LoadingProjects from "../components/LoadingProjects";

// export default function IndexPage (){
const IndexPage = () => {

    const { session, signOut } = UserAuth(); // Ensure session is being properly passed
    const { fetchFilteredProjects, fetchProjectCounts, projects, projectCounts } = useProjectPortfolio();
    const [selectedCategory, setSelectedCategory] = useState("all"); // Track selected category
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const [toast, setToast] = useState({ show: false, message: "", type: "" });
    const [loading, setLoading] = useState(true);
    
  // Fetch projects when the selected category changes
  /*useEffect(() => {
    fetchFilteredProjects(selectedCategory);
  }, [selectedCategory]);*/

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchFilteredProjects(selectedCategory);
      setLoading(false);
      //setLoading(true);
    };
    fetchData();
  }, [selectedCategory, fetchFilteredProjects]);
  

  // Fetch project counts on page load
  useEffect(() => {
    fetchProjectCounts();
  }, [fetchProjectCounts]);

  // Category options
  const categories = [
    { label: "All", value: "all" },
    { label: "Motion Design", value: "Motion Design" },
    { label: "Illustration", value: "Illustration" },
    { label: "Assets & Tools", value: "Assets & Tools" },
  ];


// **Fixed Layout Pattern (Only for lg screens)**
const layoutPattern = [
  ["lg:col-span-2", "lg:col-span-1", "lg:col-span-1"], // Row 1
  ["lg:col-span-1", "lg:col-span-1", "lg:col-span-2"], // Row 2
  ["lg:col-span-2", "lg:col-span-2"], // Row 3
  ["lg:col-span-1", "lg:col-span-1", "lg:col-span-1", "lg:col-span-1"], // Row 4
  ["lg:col-span-1", "lg:col-span-2", "lg:col-span-1"], // Row 5
];

// Animation using Framer motion
const AnimatedButton = () => {
  return (
    <motion.button
      whileHover={{ scale: 1.1 ,rotate: 2.5 }} 
      whileTap={{ scale: 0.9 }}
      className="bg-blue-500 text-white px-4 py-2 rounded"
    >
      Hover Me
    </motion.button>
  );
};
// Animation Component for Buttons
const AnimatedCategoryButton = ({ category, index, isSelected }) => {
  const count = projectCounts?.find((c) => c._id === category.value)?.count || 0;

  return (
    <div className="relative">
      <AnimatePresence>
        {hoveredIndex === index && (
          <motion.div
            layoutId="hoverBackground"
            className="absolute inset-0 bg-blue-500 rounded-full z-0"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
            }}
          />
        )}
      </AnimatePresence>
      <motion.button
        onClick={() => setSelectedCategory(category.value)}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(-1)}
        className={`relative z-0 px-4 py-2 rounded-full font-medium transition-all ${
          isSelected
            ? "bg-blue-500 text-white"
            : "bg-gray-200 text-gray-800 hover:text-white"
        }`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {category.label} ({count})
      </motion.button>
    </div>
  );
};


// HTML render 

return (
  <>
  <Helmet>
      <title>Lotfi Jilal | Motion Designer & Illustrator in Morocco</title>
      <meta 
        name="description" 
        content="Professional motion designer and illustrator based in Casablanca, Morocco. 8+ years experience in explainer videos, animation, and digital storytelling."
      />
      <meta
        name="keywords"
        content="motion designer Morocco, illustrator Casablanca, web developer Rabat, animation services Morocco, explainer videos, digital storytelling"
      />
      <meta property="og:title" content="Lotfi Jilal | Creative Motion Designer" />
      <meta property="og:description" content="Professional animation and illustration services based in Morocco" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://lotfijilal.com" />
      
      {/* Schema markup for better search results */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Lotfi Jilal",
            "jobTitle": "Motion Designer & Illustrator",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Casablanca",
              "addressRegion": "Morocco"
            },
            "description": "Professional motion designer with 8+ years experience in animation and digital storytelling",
            "url": "https://lotfijilal.com",
            "sameAs": [
              "https://www.linkedin.com/in/lotfi-jilal-0118bb165/",
              "https://vimeo.com/bloodlust"
            ]
          }
        `}
      </script>
    </Helmet>
  <body className=" bg-white min-h-screen flex flex-col items-center pt-44 pb-12">
  {/* Toast at the root level */}
  <Toast show={toast.show} message={toast.message} type={toast.type} />
  <header>
    <h1 className="text-8xl font-mono font-bold text-gray-800 text-center mt-4">Lotfi Jilal</h1>
    <p className="font-montserrat font-lexend-400 text-2xl  text-gray-800 text-center mb-14 ">Direction, Animation & Illustration </p>
    </header>
  <div className="flex space-x-4 mb-6">
  {categories.map((category) => {
  // Add a check if projectCounts is available and is an array
  const count = projectCounts && Array.isArray(projectCounts)
    ? projectCounts.find((c) => c._id === category.value)?.count || 0
    : 0;
  return (
    <button
      key={category.value}
      onClick={() => setSelectedCategory(category.value)}
      className={`px-4 py-2 rounded-full font-medium transition-transform transform hover:scale-105 ${
        selectedCategory === category.value
          ? "bg-gray-500 text-white"
          : "bg-gray-200 text-gray-800 hover:bg-gray-500 hover:text-white"
      }`}
    >
      {category.label} ({count})
    </button>
  );
})}
</div>
 {/* Loading Indicator */}
{loading ? (
   <LoadingProjects />
) : (
<main className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-6 w-full max-w-full">
      {[...projects].reverse().map((project, index) => { 
      // Track cumulative projects per row
      let cumulativeProjects = 0;
      let rowIndex = 0;

      // Find which row the current project belongs to
      while (index >= cumulativeProjects) {
        cumulativeProjects += layoutPattern[rowIndex % layoutPattern.length].length;
        rowIndex++;
      }

      // Get the current row's pattern (0-4, then loops)
      const currentRow = layoutPattern[(rowIndex - 1) % layoutPattern.length];
      
      // Position within the current row
      const positionInRow = index - (cumulativeProjects - currentRow.length);
      const colSpanClass = currentRow[positionInRow];

      return (
        <div
          key={project._id}
          className={`transition-transform ${colSpanClass}`}
          //whileHover={{ scale: 1.05 }}
        >
          <ProjectCover project={project} />
        </div>
      );
    })}
  </main>
   )}
</body>
</>
);
};
export default IndexPage ;
