import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserAuth } from "../context/AuthContext";
import LoadingPage from "./LoadingPage";
import { Link } from "react-router-dom";
import { FiChevronLeft, FiChevronRight, FiX, FiArrowUp } from "react-icons/fi";

const API_URL = process.env.REACT_APP_API_URL;

const GetSectionsPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const { session } = UserAuth();
  const [allProjects, setAllProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(-1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);



  const getAdjacentProjects = () => {
    if (currentProjectIndex === -1 || allProjects.length < 2) return {};

    const previousIndex = currentProjectIndex > 0 ? currentProjectIndex - 1 : null;
    const nextIndex = currentProjectIndex < allProjects.length - 1 ? currentProjectIndex + 1 : null;

    return {
      previous: previousIndex !== null ? allProjects[previousIndex] : null,
      next: nextIndex !== null ? allProjects[nextIndex] : null,
    };
  };

  useEffect(() => {
    const fetchAllProjects = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/projects`);
        const projects = response.data.data;
        setAllProjects(projects);

        const index = projects.findIndex((p) => p._id === projectId);
        setCurrentProjectIndex(index);
      } catch (err) {
        console.error("Error fetching projects:", err);
      }
    };

    const fetchSections = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/sections/${projectId}`);
        setSections(response.data.data);
      } catch (err) {
        console.error("Error fetching sections:", err.message);
        setError("Failed to fetch sections. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAllProjects();
    fetchSections();
  }, [projectId]);

  const handleDelete = async (id, e) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Section?");

    if (!confirmDelete) return;
    try {
      const res = await fetch(`${API_URL}/api/sections/${id}`, { method: "DELETE" });
      const data = await res.json();

      if (!data.success) {
        setToast({ show: true, message: data.message, type: "error" });
      } else {
        setToast({ show: true, message: "Section deleted successfully", type: "success" });
        setSections((prevSections) => prevSections.filter((section) => section._id !== id));
      }
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/editsectionpage/${id}`);
  };

  // handel click outside 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains('modal-backdrop')) {
        closeModal();
      }
    };

    if (selectedImage) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedImage]);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Add scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const { previous, next } = getAdjacentProjects();

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg font-semibold text-red-600">{error}</p>
      </div>
    );
  }
  // Add this function at the top of your component
  const getVimeoId = (url) => {
    const regex = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/|src="https:\/\/player\.vimeo\.com\/video\/)(\d+)/;
    const match = url?.match(regex);
    return match ? match[1] : null;
  };

  return (
    <div className="min-h-screen bg-white mt-24">
      <main className="container mx-auto px-4 py-8 w-full max-w-full text-center">
        {sections.length === 0 ? (
          <>
            <div className="flex items-center justify-center h-64">
              <p className="text-gray-600 text-xl text-center">No sections available for this project.</p>
            </div>
            <div className="flex items-center justify-center">
              <button
                onClick={() => navigate(`/addsection/${projectId}`)}
                className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition"
              >
                Add Section
              </button>
            </div>
          </>
        ) : (
          sections.map((section) => {
            // Extract Vimeo ID if videoEmbed exists
            const vimeoId = section.videoEmbed?.match(/(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/)?.[1];

            return (
              <section key={section._id} className="py-12">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 md:col-span-8 md:col-start-3">
                    <h2 className="text-6xl font-bold text-gray-800 mb-4">{section.title}</h2>
                    <p className="text-gray-600 text-xl mb-6">{section.description}</p>

                    <div className="space-y-6">
                      {/* Resources Grid */}
                      {section.resources && section.resources.split(",").length > 0 && (
                        <div className={`grid gap-4 ${section.resources.split(",").length === 1
                            ? "grid-cols-1"
                            : section.resources.split(",").length === 2
                              ? "grid-cols-2"
                              : section.resources.split(",").length === 3
                                ? "grid-cols-2 grid-rows-2"
                                : section.resources.split(",").length === 4
                                  ? "grid-cols-2 grid-rows-2"
                                  : "grid-cols-3 grid-rows-2"}`}
                        >
                          {section.resources.split(",").filter(Boolean).map((resource, index) => (
                            <img
                              key={index}
                              src={resource}
                              alt={`Resource ${index + 1}`}
                              className="w-full h-full object-cover rounded-md shadow-md cursor-pointer"
                              onClick={() => openModal(resource)}
                              style={{
                                gridColumn: section.resources.split(",").length === 3 && index === 2 ? "span 2" : "auto",
                              }}
                            />
                          ))}
                        </div>
                      )}

                      {/* Video Display Section */}
                      {vimeoId ? (
                        <div className="aspect-video w-full rounded-lg overflow-hidden shadow-md">
                          <iframe
                            src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0`}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title={section.title}
                          />
                        </div>
                      ) : section.video ? (
                        <video
                          controls
                          className="w-full rounded-lg shadow-md"
                          src={section.video}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Edit/Delete Buttons */}
                {session?.user && (
                  <div className="flex items-center justify-center mt-5">
                    <button
                      onClick={() => handleEdit(section._id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => handleDelete(section._id, e)}
                      className="bg-red-500 text-white px-4 py-2 rounded-md"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </section>
            );
          })
        )}
      </main>

      {session?.user && (
        sections.length === 0 ? (
          <></>
        ) : (
          <div className="flex flex-col items-center justify-center py-16">
            <button
              onClick={() => navigate(`/addsection/${projectId}`)}
              className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition"
            >
              Add Section
            </button>
          </div>
        )
      )}

      <footer className="bottom-0 left-0 right-0 bg-white border-t border-gray-200">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          {previous ? (
            <Link
              to={`/sections/${previous._id}`}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
            >
              <FiChevronLeft className="text-xl" />
              <div className="text-left">
                <span className="text-2xl font-bold block">Previous</span>
                <span className="font-medium">{previous.title}</span>
              </div>
            </Link>
          ) : (
            <div />
          )}

          {next && (
            <Link
              to={`/sections/${next._id}`}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
            >
              <div className="text-right">
                <span className="text-2xl font-bold block">Next</span>
                <span className="font-medium">{next.title}</span>
              </div>
              <FiChevronRight className="text-xl" />
            </Link>
          )}
        </div>
      </footer>

      {/* Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 modal-backdrop">
          <div className="relative max-w-full max-h-full p-4">
            <img
              src={selectedImage}
              alt="Full Screen"
              className="max-h-[90vh] max-w-[90vw] object-contain"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white rounded-full p-1 bg-opacity-55 hover:bg-gray-200 transition-colors mt-2 mr-2"
            >
              <FiX className="text-3xl" />
            </button>
          </div>
        </div>
      )}
      {/* Scroll to Top Button */}
      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-0 right-0 mb-4 mr-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-colors duration-200 z-50"
        >
          <FiArrowUp className="text-xl" />
        </button>
      )}
    </div>
  );
};

export default GetSectionsPage;