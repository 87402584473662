import React from "react";
import { Link } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;
const supabaseFolderUrl = "https://bemuamatjzuintsydbou.supabase.co/storage/v1/object/public/uploads/bloodlust" ; 


const AboutPage = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-white px-4 mt-10">
  <div className="bg-white border-2 border-gray-300 rounded-lg p-6 max-w-4xl w-full flex flex-col md:flex-row items-stretch md:space-x-8 shadow-sm">
    
    {/* Image Section */}
    <div className="w-full md:w-1/3 flex justify-center">
      <img
        src={`${supabaseFolderUrl}/About_Profile_Picture.jpg`}
        alt="Portrait"
        className="w-full h-full md:h-auto object-cover rounded-lg"
      />
    </div>

    {/* Text Section */}
    <div className="w-full md:w-2/3 flex flex-col justify-center text-center md:text-left">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">About Me</h2>
      <p className="text-gray-600 leading-relaxed">
        Oh hey! Glad to see you here! <br />
        I'm <span className="font-bold">Lotfi</span>, a
        <span className="font-bold"> motion designer, illustrator, and web developer</span> based in Morocco.
        With <span className="font-bold">8 years of professional experience</span>, I've collaborated with
        various <span className="font-bold">agencies and studios</span>, working primarily on
        <span className="italic"> explainer videos, short films, and digital storytelling.</span> <br />

        <span className="font-bold">My story in a nutshell...</span> <br />
        Ever since I was a kid, I've been <span className="font-bold">curious and ambitious</span> about animation and computers.
        Watching <span className="font-bold">Dragon Ball</span> as a child sparked my fascination with how drawings could
        come to life on screen. This led me to start <span className="font-bold">drawing traditionally</span>,
        experimenting with <span className="font-bold">Flash animation</span>, and diving deep into the creative world. <br />

        I pursued my passion academically, earning a <span className="font-bold">Diploma in Multimedia Development</span>,
        followed by a <span className="font-bold">Bachelor’s degree in Web Development and Database Administration</span>.
        This blend of <span className="font-bold">art and technology</span> allows me to merge
        <span className="italic"> design, animation, and coding</span> to create compelling digital experiences. <br />

        Over the years, I’ve had the honor of working with <span className="font-bold">incredible creative talents</span>,
        and I look forward to collaborating on exciting new projects. <br />

        Currently available for <span className="font-bold">freelance work</span>—let’s connect! <br />
        <Link to="mailto:lotfi.jilal@gmail.com" className="font-bold text-gray-600 hover:text-gray-800 cursor-pointer">
          lotfi.jilal@gmail.com
        </Link>
      </p>
    </div>
  </div>
</div>

    </>
  );
};

export default AboutPage;
