import {create} from "zustand";
const API_URL = process.env.REACT_APP_API_URL; 
const SUPABASE_STORAGE_URL = process.env.REACT_APP_SUPABASE_STORAGE_URL;
//console.log("API URL:", API_URL); // Debugging: check if it's correct

export const useProjectPortfolio = create ((set) => ({
    projects: [],
    setProjects : (projects) => set ({ projects }),
// Create Project function : 
    addProject : async (newProject) => {
        if( !newProject.title || !newProject.cover || !newProject.role || !newProject.category ){
            return {success:false , message : "Please fill in all Feilds !"}
        }
        //const res = await fetch ("http://localhost:5000//api/projects" , {
          const res = await fetch (`${API_URL}/api/projects` , {   
            method: "POST" , 
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify(newProject)
        }) 
        const data = await res.json();
        set((state) => ({projects: [...state.projects , data.data]}))
        return {success:true , message : "Project created successfully!"}
 },

// Get Projects function : 
fetchProjects : async () => {
    const res = await fetch(`${API_URL}/api/projects`);
    const data = await res.json();
    set ({ projects : data.data});

},

// => "Assets%20%26%20Tools"
// Get Projects with Filter : 
fetchFilteredProjects: async (category) => {
  const encodedCategory = encodeURIComponent(category);
  console.log("Encoded category:", encodedCategory); // Should show "Assets%20%26%20Tools"
  const res = await fetch(`${API_URL}/api/projects/filter?category=${encodedCategory}`);
  const data = await res.json();
  set({ projects: data.data });
},
/*
fetchFilteredProjects: async (category) => {
    console.log("Fetching projects for category:", category);
    const res = await fetch(`${API_URL}/api/projects/filter?category=${category}`);
    //const res = await fetch(`${API_URL}/api/projects/filter?category=${encodedCategory}`);
    const data = await res.json();
    set({ projects: data.data });
  },*/



  // Count project by category:
  fetchProjectCounts: async () => {
    try {
      const response = await fetch(`${API_URL}/api/projects/counts`);
      const data = await response.json();

      if (data.success) {
        set({ projectCounts: data.data }); // Update projectCounts state
      } else {
        console.error("Failed to fetch project counts:", data.message);
      }
    } catch (error) {
      console.error("Error fetching project counts:", error);
    }
  },
   

deletProject: async (pid) => {
    try {
      // Send the DELETE request to the API
      const res = await fetch(`${API_URL}/api/projects/${pid}`, {
        method: "DELETE",
      });
  
      // Parse the response
      const data = await res.json();
      console.log("API response:", data); // Log API response for debugging
  
      // Check the success flag in the response data
      if (!data.success) {
        return { success: false, message: data.message || "Failed to delete the project" };
      }
  
      // Update state to remove the deleted project without the need to refresh
      set((state) => ({
        projects: state.projects.filter((project) => project._id !== pid),
      }));
  
      // Return success with the message from the response
      return { success: true, message: data.message || "Project deleted successfully!" };
    } catch (error) {
      console.error("Error deleting project:", error);
  
      // Handle unexpected errors
      return { success: false, message: "An unexpected error occurred while deleting the project" };
    }
  },
  

//Update function
updateProject: async (updatedProject) => {
    const { _id, title, role, category, cover } = updatedProject;

    if (!title || !role || !category || !cover) {
        return { success: false, message: "Please fill in all fields!" };
    }

    try {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("role", role);
        formData.append("category", category);
        formData.append("cover", cover); // Append the cover file

        const res = await fetch(`${API_URL}/api/projects/${_id}`, {
            method: "PUT",
            body: formData, // Send FormData directly
        });

        const data = await res.json();

        if (!data.success) {
            return { success: false, message: data.message || "Failed to update the project." };
        }

        // Update the projects in the state
        set((state) => ({
            projects: state.projects.map((project) =>
                project._id === _id ? { ...project, ...updatedProject } : project
            ),
        }));

        return { success: true, message: "Project updated successfully!" };
    } catch (error) {
        console.error("Error updating project:", error);
        return { success: false, message: "An unexpected error occurred while updating the project." };
    }
}


}));

