import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
const API_URL = process.env.REACT_APP_API_URL;

const EditSectionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [section, setSection] = useState(null);
  const [resourcesFiles, setResourcesFiles] = useState([]); // Updated: Multiple files
  const [videoFile, setVideoFile] = useState(null);
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [videoType, setVideoType] = useState('upload');

  useEffect(() => {
    const fetchSection = async () => {
      try {
        const response = await fetch(`${API_URL}/api/sections/edit/${id}`);
        const data = await response.json();

        if (data.success) {
          setSection(data.data);
        } else {
          console.error("Error fetching section:", data.message);
        }
      } catch (error) {
        console.error("Error in fetchSection:", error.message);
      }
    };

    fetchSection();
  }, [id]);

  // Handle Multiple Resources
  const handleResourcesChange = (event) => {
    const files = Array.from(event.target.files);
    setResourcesFiles(files);
  };

  // Handle Video File
  const handleVideoChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

// Add video type handler
const handleVideoTypeChange = (type) => {
  setVideoType(type);
  // Clear existing files when switching types
  if (type === 'embed') {
    setVideoFile(null);
  } else {
    setSection(prev => ({ ...prev, videoEmbed: "" }));
  }
};

// Vimeo validation function
const validateVimeo = (input) => {
  const vimeoRegex = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/|src="https:\/\/player\.vimeo\.com\/video\/)(\d+)/;
  const match = input.match(vimeoRegex);
  return match && match[1] ? { isValid: true, videoId: match[1] } : { isValid: false };
};

// Updated handleUpdate function   ****** : 
const handleUpdate = async () => {
  // Validate video embed
  if (videoType === 'embed' && section.videoEmbed) {
    const { isValid } = validateVimeo(section.videoEmbed);
    if (!isValid) {
      setToast({
        show: true,
        message: "Invalid Vimeo URL. Example: https://vimeo.com/123456789",
        type: "error"
      });
      return;
    }
  }

  const formData = new FormData();
  formData.append("title", section?.title || "");
  formData.append("description", section?.description || "");

  // Append video data based on type
  if (videoType === 'upload' && videoFile) {
    formData.append("video", videoFile);
  } else if (videoType === 'embed' && section.videoEmbed) {
    formData.append("videoEmbed", section.videoEmbed);
  }

  // Append resources
  resourcesFiles.forEach(file => formData.append("resources", file));

  try {
    setIsUploading(true);
    setUploadProgress(0);

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", `${API_URL}/api/sections/${id}`);

    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      }
    });

    xhr.onload = () => {
      setIsUploading(false);
      if (xhr.status >= 200 && xhr.status < 300) {
        const data = JSON.parse(xhr.responseText);
        if (data.success) {
          navigate(`/sections/${section.project}`);
        } else {
          setToast({ show: true, message: data.message, type: "error" });
        }
      } else {
        setToast({ show: true, message: "Update failed", type: "error" });
      }
    };

    xhr.onerror = () => {
      setIsUploading(false);
      setToast({ show: true, message: "Network error", type: "error" });
    };

    xhr.send(formData);

  } catch (error) {
    console.error("Error updating section:", error);
    setIsUploading(false);
    setToast({ show: true, message: error.message, type: "error" });
  }
};

// Spinner Component
const SimpleSpinner = () => (
  <div className="mx-auto mb-4">
    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);
  return (

    <div className="bg-white flex justify-center items-center min-h-screen">
    <div className="bg-white border-2 rounded-lg p-6 max-w-md w-full">
  {/* Toast */}
  <Toast show={toast.show} message={toast.message} type={toast.type} />
  {/* Add the progress display */}
  {isUploading && <SimpleSpinner progress={uploadProgress} />}
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">Edit Section</h1>
      <div className="space-y-4">

        {/* Title Input */}
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            id="title"
            value={section?.title || ""}
            onChange={(e) => setSection({ ...section, title: e.target.value })}
            placeholder="Enter title"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
          />
        </div>

        {/* Description Input */}
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            value={section?.description || ""}
            onChange={(e) => setSection({ ...section, description: e.target.value })}
            placeholder="Enter description"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
          ></textarea>
        </div>

        {/* Resources Input */}
        <div>
          <label htmlFor="resources" className="block text-sm font-medium text-gray-700">
            Resources
          </label>
          <input
            type="file"
            multiple
            id="resources"
            onChange={handleResourcesChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
          />
        </div>
{/* Video Type Selector */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Video Type
          </label>
          <div className="flex gap-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="videoType"
                value="upload"
                checked={videoType === 'upload'}
                onChange={(e) => handleVideoTypeChange('upload')}
                className="mr-2"
              />
              Upload Video File
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="videoType"
                value="embed"
                checked={videoType === 'embed'}
                onChange={(e) => handleVideoTypeChange('embed')}
                className="mr-2"
              />
              Embed Video URL
            </label>
          </div>
        </div>

        {/* Video Input Section */}
        {videoType === 'upload' ? (
          <div className="mb-4">
            <label htmlFor="video" className="block text-sm font-medium text-gray-700">
              Video File (mp4 format)
            </label>
            <input
              type="file"
              id="video"
              onChange={handleVideoChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              accept="video/mp4"
            />
            {section?.video && !videoFile && (
              <p className="text-sm text-gray-500 mt-1">
                Current video: {section.video.split('/').pop()}
              </p>
            )}
          </div>
        ) : (
          <div className="mb-4">
            <label htmlFor="videoEmbed" className="block text-sm font-medium text-gray-700">
              Vimeo Embed URL
            </label>
            <input
              type="url"
              id="videoEmbed"
              value={section?.videoEmbed || ''}
              onChange={(e) => setSection({ ...section, videoEmbed: e.target.value })}
              placeholder="https://vimeo.com/123456789"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        )}

        {/* Submit Button */}
        <div>
          <button
            type="button"
            onClick={handleUpdate}
            disabled={isUploading}
            className={`w-full ${
              isUploading ? 'bg-blue-300' : 'bg-blue-500'
            } text-white font-medium py-2 px-4 rounded-md shadow hover:bg-blue-600 transition-colors`}
          >
            {isUploading ? 'Updating...' : 'Update Section'}
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default EditSectionPage;
