import React from "react";

const Toast = ({ show, message, type }) => {
  if (!show) return null;

  return (
    <div className={`fixed bottom-4 left-1/2 -translate-x-1/2
      p-4 rounded shadow-lg text-white z-[9999] min-w-[300px] text-center
      ${type === "success" ? "bg-green-500" : "bg-red-500"}`}
    >
      {message}
    </div>
  );
};

export default Toast;
