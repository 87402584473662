import React, { useState } from "react";
import { supabase } from "../supabaseClient";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleResetPassword = async () => {
    const { error } = await supabase.auth.updateUser({ password });
    if (error) {
      setError(error.message);
    } else {
      setMessage("Password has been reset successfully.");
    }
  };

  return (
<div className="space-y-4">
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded shadow-md">
      <h1 className="text-2xl font-semibold mb-6 text-center">
         Reset Password
      </h1>
      {message && <p className="text-green-600 text-center p-2 bg-green-200 rounded border-2 border-green-300 mt-4">{message}</p>}
      {error && <p className="text-red-600 text-center p-2 bg-red-200 rounded border-2 border-red-300 mt-4">{error}</p>}
      <input
        type="password"
        placeholder="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 my-4"/>
      <button 
      onClick={handleResetPassword}
      className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-500">
        Reset Password
      </button>
    </div>
    </div>
    </div>
  );
};

export default ResetPasswordPage;