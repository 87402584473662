import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

//const [forceRender, setForceRender] = useState(0);

const DashboardPage = () => {
  const { session, signOut } = UserAuth(); // Ensure session is being properly passed
  const navigate = useNavigate();
  const [error, setError] = useState(null); // Define setError for error handling
  
  // Debugging for session value
  console.log("Session:", session);

 

  return (
    <div class="bg-gray-100 min-h-screen flex flex-col items-center pt-36">

    <div>
      <h1 className=" text-gray-800  font-secondary text-center text-6xl text-bold">Dashboard</h1>
      <h2 className=" text-gray-600   text-center text-xl " >Welcome, {session?.user?.email || "Guest"}</h2>
      {error && <p className="text-red-500">{error}</p>} {/* Show error message */}
    </div>
    
    </div>
  );
};

export default DashboardPage;
