import React from "react";
const API_URL = process.env.REACT_APP_API_URL;
const supabaseFolderUrl = "https://bemuamatjzuintsydbou.supabase.co/storage/v1/object/public/uploads/bloodlust" ; 

const ReelPage = () => {
  return (
    <>
<div className="flex justify-center items-center min-h-screen bg-white px-4 mt-24">
  <div className="w-full md:w-2/3 text-center">
    <h2 className="text-6xl font-bold text-gray-800 mb-10">Reel</h2>
    <div className="relative overflow-hidden rounded-md shadow-md" style={{ paddingTop: '56.25%' /* 16:9 aspect ratio */ }}>
      <iframe
        src="https://player.vimeo.com/video/1007253651?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
        className="absolute top-0 left-0 w-full h-full"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
        title="Showreel 2024 - Lotfi Jilal"
        allowFullScreen
      />
    </div>
  </div>
</div>
</>
  );
};

export default ReelPage;
