import Layout from './components/Layout';
import { createBrowserRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SigninPage from './pages/SigninPage';
import SignupPage from  './pages/SignupPage'
import DashboardPage from  './pages/DashboardPage'
import PrivateRoute from './pages/PrivateRoute';
import LoadingPage from './pages/LoadingPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AddProjectPage from './pages/AddProjectPage';
import AddSectionPage from './pages/AddSectionPage';
import GetSectionsPage from './pages/GetSectionsPage';
import EditSectionPage from './pages/EditSectionPage';
import AboutPage from './pages/AboutPage';
import ReelPage from './pages/ReelPage';



export const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />, // Wrap all routes with Layout
      children: [
        { index: true, element: <HomePage /> }, // Default page ("/")
        //{ path: "signup", element: <SignupPage /> },
        { path: "signin", element: <SigninPage /> },
        { path: "loadingpage", element: <LoadingPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "addproject", element: <PrivateRoute><AddProjectPage /> </PrivateRoute> },
        { path: "about", element: <AboutPage /> },
        { path: "reel", element: <ReelPage /> },
        { path: "editsectionpage/:id", element: <PrivateRoute> <EditSectionPage /> </PrivateRoute> },
        { path: "sections/:projectId", element: <GetSectionsPage /> }, // Dynamic project route
        { path: "addsection/:projectId", element:<PrivateRoute>  <AddSectionPage /> </PrivateRoute> }, // Add dynamic projectId
        { path: "dashboard", element: <PrivateRoute> <DashboardPage /> </PrivateRoute> },
      ],
    },
  ]);

  