
import react from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { supabase } from '../supabaseClient';


const SigninPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ error , setError] = useState("");
  const [ loading , setLoading] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const { resetPassword } = UserAuth();

 //Check if UserAuth is undefined
  const { session, signinUser , signupGoogleOAuth } = UserAuth() || {};
     if (!signinUser) {
       console.error("AuthContext not initialized. Make sure AuthContextProvider is wrapping your app.");
       return null; // Or render an error page
     }

//supabase sign in
  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(""); // Clear any previous errors
  
    try {
      const result = await signinUser(email, password);
      if (result.success) {
        navigate("/dashboard"); // Navigate to the dashboard on success
      } else {
        setError(result.error.message || "Email or password is incorrect !");
      }
    } catch (err) {
      setError("An unexpected error occurred.");
      //setError("Your email is not confirmed.");
    } finally {
      setLoading(false); // End loading state
    }
  };


 // forgot password :

 const handleForgotPassword = async () => {
  setError("");
  setMessage("");
  if (!email) {
    setError("Please enter your email to reset your password.");
    return;
  }

  const response = await resetPassword(email);
  if (response.success) {
    setMessage("A password reset email has been sent to your inbox.");
  } else {
    setError(response.error || "Failed to send reset email.");
  }
};


return(
<>


<div className="space-y-4">
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="max-w-md w-full bg-white p-8 rounded border-2">
      <form onSubmit={handleSignIn}> 
        <h1 className="text-2xl font-semibold mb-6 text-center">
          Signin
        </h1>
          <div>
            <input 
               placeholder="Email"
               type="email"
               id="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               required
               className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 my-4"/>
          </div>
          <div>
            <input 
              placeholder="password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 my-4"/>
          </div>
          <div className="flex items-center justify-between">
          <p  className="block text-sm font-medium text-gray-700"> You dont have an account ?</p> 
            <Link to="/signup" className="text-sm text-gray-900 hover:underline">Signup</Link>     
          </div>
          <button 
            type="button"  
            onClick={handleForgotPassword} 
            className="text-sm text-gray-900 hover:underline mb-2">
              Forgot password?
          </button>     
          <button type="submit" className="w-full bg-lj-black text-white py-2 px-4 rounded hover:bg-gray-600">
             Signin
          </button> 
          </form> 

          {error && (
          <p className="text-red-600 text-center p-2 bg-red-200 rounded border-2 border-red-300 mt-4">
            {error}
          </p>
        )}
        {message && (
          <p className="text-green-600 text-center p-2 bg-green-200 rounded border-2 border-green-300 mt-4">
            {message}
          </p>
        )}
      </div>
    </div>
  </div>
  
  </>
    );
}
export default SigninPage ;

//Google OAuth
/*
          <button 
              type="button"
              onClick={signupGoogleOAuth}
              className="w-full bg-gray-300 text-gray-500  hover:text-gray-700 py-2 px-4 rounded hover:bg-gray-400 my-4">
              Sign in with google
          </button> 
*/