import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useAuth } from "../context/AuthContext";
import LoadingPage from './LoadingPage';
import { Outlet } from "react-router-dom";


  const ProtectedRoute =  ({ children }) => {
    const { session, isLoading } = UserAuth();
  
    if (isLoading) {
      return <LoadingPage />;
    }
  
    if (!session) {
      return <Navigate to="/signin" replace />;
    }
    // Debugging session inside the protected route
   // console.log("AuthProtectedRoute - Session:", session);
  // Render children (protected content)
  return children;
    
  };

export default ProtectedRoute;

/*
// Solve the Refreching problem
  const ProtectedRoute = () => {
    const { session, isLoading } = UserAuth();
  
    if (isLoading) {
      return <LoadingPage />;
    }
  
    if (!session) {
      return <Navigate to="/signin" replace />;
    }
    // Debugging session inside the protected route
    console.log("AuthProtectedRoute - Session:", session);
    return <Outlet />;
    
  };

export default ProtectedRoute;
*/


/*
const ProtectedRoute = ({ children }) => {
  const { session } = UserAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (session) {
      setLoading(false); // If session exists, stop loading
    }
  }, [session]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state while session is being restored
  }
  
  // Redirect to signin if no session is found
 if (!session) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default ProtectedRoute;
*/



/*
const PrivateRoute = ({ children }) => {
  const { session, loading } = UserAuth();

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking auth
  }

  return session ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
*/