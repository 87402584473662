import Footer from "./Footer";
import Header from "./Header";
import Toast from "./Toast";
import { Outlet } from "react-router-dom";

export default function Layout({children}) {
    return (
        <main className="bg-gray-100">
            <Header />
            <Toast />
            <Outlet />  
            <Footer />      
        </main>
    )
}

