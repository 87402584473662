const LoadingPage = () => {
    return (
      <main>
        <section className="main-container">
          <h1 className="header-text">Loading... </h1>
        </section>
      </main>
    );
  };
  
  export default LoadingPage;