
import {createContext, useState , useContext, useEffect } from "react" ;
import { supabase } from "../supabaseClient";

//const AuthContext = createContext();
const AuthContext = createContext({ session: null, user: null, isLoading: true});

export const AuthContextProvider = ({children}) => {
const [session, setSession] = useState(null);
const [user, setUser] = useState(null);
const [isLoading, setIsLoading] = useState(true); 

//Initialize session and set up listener

useEffect(() => {
  // Initial session load
  const initializeSession = async () => {
    const { data } = await supabase.auth.getSession();
    setSession(data.session);
    setUser(data.session?.user || null);
    setIsLoading(false);
  };

  initializeSession();

  // Listen to authentication state changes
  const { data: authListener } = supabase.auth.onAuthStateChange(
    (_, newSession) => {
      setSession(newSession);
      setUser(newSession?.user || null);
           
    }
  );

  return () => {
    if (authListener?.subscription) {
      authListener.subscription.unsubscribe();
    }
  };
  
}, []);



/*
  // Initialize session and set up listener

  useEffect(() => {
   const initSession = async () => {
     const { data: { session }, error } = await supabase.auth.getSession();
     if (error) console.error("Error getting session:", error);
     setSession(session); // Set the session on initialization

     // Listen for session changes
     const { data: subscription } = supabase.auth.onAuthStateChange((_event, session) => {
       console.log("Auth state changed:", _event, session);
       setSession(session); // Update session on login/logout
     });

     // Cleanup the subscription on unmount
     return () => {
       subscription.unsubscribe?.(); // Avoid errors if unsubscribe is not a function
     };
   };
   // debuging session 
   const debugSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      console.log("Initial session:", session, "Error:", error);
  
      supabase.auth.onAuthStateChange((_event, session) => {
        console.log("Session updated:", _event, session);
        setSession(session);
      });
    };
  
    debugSession();

   initSession();
 }, []);
 */


 
 /*
useEffect (()=> {
   supabase.auth.getSession().then(({data: {session}})=> {
      setSession(session);
   })

   supabase.auth.onAuthStateChange((_event, session )=> {
      setSession(session);
   })
}, []);
*/


// Google OAuth login
  const signupGoogleOAuth = async () => {
   try {
     const { error } = await supabase.auth.signInWithOAuth({
       provider: "google",
     });

     if (error) {
       console.error("Google OAuth sign-in error:", error);
       return { success: false, error };
     }

     return { success: true };
   } catch (err) {
     console.error("Unexpected error during Google sign-in:", err);
     return { success: false, error: err };
   }
 };

/*
//signup with google  function :
const signupGoogleOAuth = async ( ) => {
   await supabase.auth.signInWithOAuth({
     provider: "google" ,
   })
 }
*/

// Sign UP creating New user

const signUpNewUser = async (email, password ) => {
   try {
     const { data, error } = await supabase.auth.signUp({
       email: email.toLowerCase(),
       password: password,
      });
 
     if (error) {
       console.error("Error signing up:", error);
       return { success: false, error };
     }
 
     console.log("Sign-up success:", data);
     return { success: true, data };
   } catch (err) {
     console.error("Unexpected error during sign-up:", err);
     return { success: false, error: err };
   }
 };
 




// Sign in 
const signinUser = async (email, password) => {
   try{
       const{data , error} = await supabase.auth.signInWithPassword({
         email: email,
         password: password,
       });
       if(error){
         console.error("sign in error occured :" , error);
         return {success: false , error: error.message};
       }
       //console.log("sign-in success:" , data)
       return {success: true , data};
   }catch(error){
       console.error("an error occured :", error);
   }
}

//reset password
const resetPassword = async (email) => {
  try {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`, // Add a redirect URL if needed
    });

    if (error) {
      console.error("Error sending password reset email:", error.message);
      return { success: false, error: error.message };
    }

    return { success: true, data };
  } catch (err) {
    console.error("Unexpected error during password reset:", err);
    return { success: false, error: err.message };
  }
};
 


// Sign Out 
const signOut = () => {
   const{ error } = supabase.auth.signOut();
   if (error){
      console.error("there was an error:" , error);
   }
}


// Render 
    return (
       <AuthContext.Provider value={{session, isLoading , signUpNewUser , signinUser, signupGoogleOAuth ,signOut , resetPassword}}>
        {children}
       </AuthContext.Provider>       
    )
}



// Custom hook to use AuthContext
export const UserAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
};