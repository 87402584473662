import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { motion } from "framer-motion";
import { Menu, X } from "lucide-react"; // For hamburger icon
const API_URL = process.env.REACT_APP_API_URL;
const supabaseFolderUrl = "https://bemuamatjzuintsydbou.supabase.co/storage/v1/object/public/uploads/bloodlust" ; 

export default function Header() {
  const { session, signOut } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [isOpen, setIsOpen] = useState(false); // Mobile menu state

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/signin");
    } catch (err) {
      console.error("Error signing out:", err);
    }
  };

  const navItems = [
    { label: "Work", to: "/" },
    { label: "Reel", to: "/reel" },
    { label: "About", to: "/about" },
  ];

  if (session?.user) {
    navItems.push(
      { label: "New Project", to: "/addproject" },
      { label: "Dashboard", to: "/dashboard" },
      { label: "Sign Out", to: "/signin", action: handleSignOut }
    );
  } else {
    navItems.push(
      //{ label: "Register", to: "/signup" },
      //{ label: "Login", to: "/signin" }
    );
  }

  const handleLinkClick = (to, label) => {
    if (location.pathname === to) {
      navigate(to, { replace: true });
    }
    setActiveLink(label);
    setIsOpen(false); // Close mobile menu after clicking
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-white z-50 py-6">
      <div className="container mx-auto px-6 flex flex-wrap items-center justify-between">
        {/* Logo */}
        <Link to="/" className="flex justify-center w-full md:w-auto">
          <img
            src={`${supabaseFolderUrl}/LotfiJilal-logo-Picto-name-2024.png`}
            className="w-28 md:w-32"
            alt="Logo"
          />
        </Link>

        {/* Hamburger Menu (Mobile Only) */}
        <button
          className="block md:hidden text-gray-700"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <X size={28} /> : <Menu size={28} />}
        </button>

        {/* Navigation (Desktop) */}
        <nav className="hidden md:flex space-x-6 text-xl">
          {navItems.map((item, index) => (
            <motion.div
              key={index}
              className="relative group"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              {item.action ? (
                <button
                  onClick={item.action}
                  className={`font-medium text-gray-500 hover:text-gray-900 ${
                    activeLink === item.label
                      ? "underline decoration-gray-900 underline-offset-4"
                      : ""
                  }`}
                >
                  {item.label}
                </button>
              ) : (
                <Link
                  to={item.to}
                  onClick={() => handleLinkClick(item.to, item.label)}
                  className={`font-medium text-gray-600 hover:text-gray-900 ${
                    activeLink === item.label
                      ? "underline decoration-gray-900 underline-offset-4"
                      : ""
                  }`}
                >
                  {item.label}
                </Link>
              )}

              {/* Underline animation */}
              {activeLink !== item.label && (
                <motion.div
                  className="absolute bottom-0 left-0 h-[2px] bg-gray-900 group-hover:w-full w-0 transition-all duration-300"
                />
              )}
            </motion.div>
          ))}
        </nav>
      </div>

      {/* Mobile Menu (Shows when isOpen is true) */}
      {isOpen && (
        <nav className="md:hidden bg-white py-4 px-6 border-t">
          {navItems.map((item, index) => (
            <motion.div
              key={index}
              className="py-2 text-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {item.action ? (
                <button
                  onClick={item.action}
                  className="text-gray-600 text-lg font-medium block w-full"
                >
                  {item.label}
                </button>
              ) : (
                <Link
                  to={item.to}
                  onClick={() => handleLinkClick(item.to, item.label)}
                  className="text-gray-600 text-lg font-medium block"
                >
                  {item.label}
                </Link>
              )}
            </motion.div>
          ))}
        </nav>
      )}
    </header>
  );
}
