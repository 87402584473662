import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, RouterProvider , Routes, Route } from 'react-router-dom';
import { router } from './router';
import { AuthContextProvider } from './context/AuthContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <React.StrictMode>
     <AuthContextProvider>
        <RouterProvider router={router} />
     </AuthContextProvider>
  </React.StrictMode>
  </>
);

reportWebVitals();
