import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import Toast from "../components/Toast";
import { supabase } from '../supabaseClient'; // Import your Supabase client
const API_URL = process.env.REACT_APP_API_URL;

const AddSectionPage = () => {
  const { projectId } = useParams();
  //const [resourcesFile, setResourcesFile] = useState(null);
  const [resourcesFiles, setResourcesFiles] = useState([]); // <-- Store multiple files
  const [videoFile, setVideoFile] = useState(null);
  const [videoType, setVideoType] = useState('upload'); // 'upload' or 'embed'
  const navigate = useNavigate();
  const [section, setSection] = useState({
    title: "",
    description: "",
    resources: "",
    video: "",
    videoEmbed: "",
    project: projectId, // Prepopulate with the projectId
  });

  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  
// Add this validation function
const validateVimeo = (input) => {
  // Regex pattern to match both URL formats and iframe embeds
  const vimeoRegex = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/|src="https:\/\/player\.vimeo\.com\/video\/)(\d+)/;
  const match = input.match(vimeoRegex);
  
  if (match && match[1]) {
    return {
      isValid: true,
      videoId: match[1],
      embedUrl: `https://player.vimeo.com/video/${match[1]}`
    };
  }
  
  return { isValid: false };
};


    // Add video type handler
    const handleVideoTypeChange = (type) => {
      setVideoType(type);
      // Clear existing video when switching types
      if (type === 'embed') {
        setVideoFile(null);
      } else {
        setSection(prev => ({ ...prev, videoEmbed: "" }));
      }
    };

// Helper function for file validation (Supports Multiple Files)
const validateAndSetFiles = (files, allowedTypes, maxSizeMB, setFileState, isSingleFile = false) => {
  let validFiles = [];
  
  for (let file of files) {
    if (!allowedTypes.includes(file.type)) {
      setToast({ show: true, message: `Invalid file type: ${file.name}`, type: "error" });
      continue; // Skip invalid file
    }
    if (file.size > maxSizeMB * 1024 * 1024) {
      setToast({ show: true, message: `File too large: ${file.name}`, type: "error" });
      continue; // Skip large file
    }
    validFiles.push(file); // Add valid file to array
  }

  if (validFiles.length > 0) {
    if (isSingleFile) {
      setFileState(validFiles[0]); // Only set one file for videos
    } else {
      setFileState((prevFiles) => [...prevFiles, ...validFiles]); // Append valid files for multiple resources
    }
  }
};


// Handle Multiple Resources Files
const handleResourcesChange = (event) => {
  const files = Array.from(event.target.files); // Convert FileList to array
  validateAndSetFiles(files, ["image/jpeg", "image/png", "image/gif", "image/jpg"], 5, setResourcesFiles);
};

// Handle Video File
const handleVideoChange = (event) => {
  const file = event.target.files[0]; // Get only one file
  if (file) {
    validateAndSetFiles([file], ["video/mp4"], 50, setVideoFile, true); // Pass `true` for isSingleFile
  }
};

// Add Section Function
const handleAddSection = async () => {


    // Validate either video file or embed URL
    if (videoType === 'embed') {
      const validation = validateVimeo(section.videoEmbed);
      if (!validation.isValid) {
        setToast({
          show: true,
          message: "Invalid Vimeo URL or embed code. Example: https://vimeo.com/123456789 or <iframe...>",
          type: "error"
        });
        return;
      }
      // Store just the video ID or full embed URL
      section.videoEmbed = validation.embedUrl; // or validation.videoId
    }

    if (videoType === 'upload' && !videoFile && !section.title && !section.description && resourcesFiles.length === 0) {
      setToast({ show: true, message: "At least one field is required", type: "error" });
      return;
    }

  const formData = new FormData();
  formData.append("title", section.title);
  formData.append("description", section.description);
  formData.append("project", projectId);
  
  // Add videoEmbed to FormData
  if (videoType === 'embed') {
    formData.append("videoEmbed", section.videoEmbed);
  }

  resourcesFiles.forEach((file) => {
    formData.append("resources", file);
  });

  if (videoType === 'upload' && videoFile) {
    formData.append("video", videoFile);
  }
  try {
    setIsUploading(true);
    setUploadProgress(0);

    // Use XMLHttpRequest for progress tracking
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${API_URL}/api/sections/${projectId}`);

    // Track upload progress
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      }
    });

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const data = JSON.parse(xhr.responseText);
        if (data.success) {
          setToast({ show: true, message: "Section added successfully!", type: "success" });
          navigate(`/sections/${projectId}`);
        } else {
          setToast({ show: true, message: data.message || "Failed to add section", type: "error" });
        }
      } else {
        setToast({ show: true, message: "Request failed", type: "error" });
      }
      setIsUploading(false);
    };

    xhr.onerror = () => {
      setToast({ show: true, message: "Network error", type: "error" });
      setIsUploading(false);
    };

    xhr.send(formData);

  } catch (error) {
    console.error("Error adding section:", error);
    setToast({ show: true, message: error.message, type: "error" });
    setIsUploading(false);
  }
};


// Spinner Component
const SimpleSpinner = () => (
  <div className="mx-auto mb-4">
    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

return (
    <div className="bg-white flex justify-center items-center min-h-screen">
  {/* Toast */}
  <Toast show={toast.show} message={toast.message} type={toast.type} />
      <div className="bg-white border-2 rounded-lg p-6 max-w-md w-full">
    {/* Add the progress display */}
    {isUploading && <SimpleSpinner progress={uploadProgress} />}
        <h1>Add Section for Project ID: {projectId}</h1>
        <h1 className="text-2xl font-semibold text-gray-800 mb-4">Add New Section</h1>
        <div className="space-y-4">
          {/* Title Input */}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              id="title"
              placeholder="Enter section title"
              value={section.title}
              onChange={(e) => setSection({ ...section, title: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
              required
            />
          </div>

          {/* Description Input */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              placeholder="Enter section description"
              value={section.description}
              onChange={(e) => setSection({ ...section, description: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
              required
            />
          </div>

          {/* Resources Input */}
          <div>
            <label htmlFor="resources" className="block text-sm font-medium text-gray-700">
              Resources ( You can upload up to 5 images )
            </label>
            <input
              type="file"
              multiple
              id="resources"
              placeholder="Select Resources"
              onChange={handleResourcesChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-800"
            />
          </div>

 {/* Video Type Selector */}
 <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Video Type
          </label>
          <div className="flex gap-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="videoType"
                value="upload"
                checked={videoType === 'upload'}
                onChange={(e) => handleVideoTypeChange('upload')}
                className="mr-2"
              />
              Upload Video File
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="videoType"
                value="embed"
                checked={videoType === 'embed'}
                onChange={(e) => handleVideoTypeChange('embed')}
                className="mr-2"
              />
              Embed Video URL
            </label>
          </div>
        </div>

      {/* Video Input Section */}
        {videoType === 'upload' ? (
          <div>
            <label htmlFor="video" className="block text-sm font-medium text-gray-700">
              Video File (mp4 format)
            </label>
            <input
              type="file"
              id="video"
              onChange={handleVideoChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              accept="video/mp4"
            />
          </div>
        ) : (
          <div>
            <label htmlFor="videoEmbed" className="block text-sm font-medium text-gray-700">
              Video Embed URL (Vimeo)
            </label>
            <input
              type="url"
              id="videoEmbed"
              value={section.videoEmbed}
              onChange={(e) => setSection({ ...section, videoEmbed: e.target.value })}
              placeholder="https://vimeo.com/..."
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
            {videoType === 'embed' && section.videoEmbed && (
             <span className={`absolute right-3 top-2 text-sm ${
              validateVimeo(section.videoEmbed) ? 'text-green-500' : 'text-red-500'
             }`}>
               {validateVimeo(section.videoEmbed) ? '✓' : '✗'}
             </span>
           )}
          </div>
        )}
  
          {/* Submit Button */}
          <div>
          <button
            onClick={handleAddSection}
            disabled={isUploading}
            className={`w-full ${
              isUploading ? 'bg-blue-300' : 'bg-blue-500'
            } text-white font-medium py-2 px-4 rounded-md shadow hover:bg-blue-600 transition-colors`}
          >
            {isUploading ? `Uploading... ${uploadProgress}%` : 'Add Section'}
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSectionPage;



/*
// Add Section Function
const handleAddSection = async () => {
  if (!section.title && !section.description && resourcesFiles.length === 0 && !videoFile) {
    setToast({ show: true, message: "At least one field is required", type: "error" });
    return;
  }

  const formData = new FormData();
  formData.append("title", section.title);
  formData.append("description", section.description);
  formData.append("project", projectId);

  resourcesFiles.forEach((file) => {
    formData.append("resources", file);
  });

  if (videoFile) {
    formData.append("video", videoFile);
  }

  try {
    const res = await fetch(`${API_URL}/api/sections/${projectId}`, {
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    if (!data.success) {
      setToast({ show: true, message: data.message || "Failed to add section", type: "error" });
    } else {
      setToast({ show: true, message: "Section added successfully!", type: "success" });
      navigate(`/sections/${projectId}`);
    }
  } catch (error) {
    console.error("Error adding section:", error);
  } finally {
    setTimeout(() => {
      setToast({ show: false, message: "", type: "" });
    }, 3000);
  }
};
*/