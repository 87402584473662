import React from "react";

const layoutPattern = [
  ["lg:col-span-2", "lg:col-span-1", "lg:col-span-1"], // Row 1
  ["lg:col-span-1", "lg:col-span-1", "lg:col-span-2"], // Row 2
  ["lg:col-span-2", "lg:col-span-2"],                   // Row 3
  ["lg:col-span-1", "lg:col-span-1", "lg:col-span-1", "lg:col-span-1"], // Row 4
  ["lg:col-span-1", "lg:col-span-2", "lg:col-span-1"],   // Row 5
];

// Flatten the layout pattern for simplicity.
const flattenedPattern = layoutPattern.flat();

const LoadingProjects = () => {
  return (
    <>
    <p className="text-xl font-semibold text-gray-400 text-center pb-5">Loading projects ...</p>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-6 w-full max-w-full">
        
      {flattenedPattern.map((colSpanClass, index) => (
        <div
          key={index}
          className={`${colSpanClass} bg-gray-300 rounded-lg h-72`}
          style={{
            animation: "scan 2s ease-in-out infinite",
            animationDelay: `${index * 0.2}s`,
          }}
        ></div>
      ))}
    </div>
    </>
  );
};

export default LoadingProjects;
