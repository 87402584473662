
import {createClient } from "@supabase/supabase-js" ;


const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY;


export const supabase = createClient(SUPABASE_URL , SUPABASE_ANON_KEY ,{
    auth: {
      persistSession: true,
      autoRefreshToken: true
    },
  });
  


//console.log("Supabase URL:", SUPABASE_URL);
//console.log("Supabase Anon Key:", SUPABASE_ANON_KEY);
//console.log("Supabase Anon Key:", SUPABASE_SERVICE_ROLE_KEY);